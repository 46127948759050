import localizationMixins from '@/mixins/Localization'
import { sanitize } from '@/utils/TextFormatUtils'
import dayjs from 'dayjs'
export default {
  data () {
    return {
      search: '',
      sanitize,
      filters: {
        searchText: '',
        selectedObject: null,
        b: null,
        c: null
      }
    }
  },
  mixins: [localizationMixins],
  created () {
    if (this.storeSearch && this.storedSearch) {
      this.search = this.storedSearch
    }
  },
  methods: {
    getObjectValue (object, path) {
      var paths = path.split('.')
      var current = object
      var i

      for (i = 0; i < paths.length; ++i) {
        if (current[paths[i]] === undefined || current[paths[i]] === null) {
          return null
        } else {
          current = current[paths[i]]
        }
      }
      return current
    },
    searchVisibleKeys (object, search) {
      const context = this
      const visibleHeaders = this.headers.filter((h) => h.show)
      return visibleHeaders.some(header => {
        return context.sanitize(this.getObjectValue(object, header.path) || '').indexOf(search) !== -1
      })
    },
    searchKeys (object, search) {
      const context = this
      var matchesDateFormat = new RegExp(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/)
      return Object.keys(object).some(function (k) {
        if (object[k] === null) return false
        if (typeof object[k] === 'object') {
          return context.searchKeys(object[k], search)
        } else if (typeof object[k] === 'string' && matchesDateFormat.test(object[k])) {
          return dayjs(object[k]).local().format('DD/MM/YYYY HH:mm').indexOf(search) !== -1
        } else {
          return context.sanitize(object[k]).indexOf(search) !== -1
        }
      })
    },
    autocompleteFilter (item, queryText, itemText) {
      queryText = this.sanitize(queryText)
      return this.sanitize(itemText).indexOf(queryText) !== -1
    },
    textSearchByVisibleValues (items, search, filter) {
      if (search.trim() === '') {
        return items
      }
      const context = this
      search = this.sanitize(search)
      return items.filter(function (o) {
        
        return context.searchVisibleKeys(o, search)
      })
    },
    textSearchByValues (items, search, filter) {
      if (search.trim() === '') {
        return items
      }
      const context = this
      search = this.sanitize(search)
      return items.filter(function (o) {
        return context.searchKeys(o, search)
      })
    },
    filterByObject (object, items) {
      const context = this
      if (!object) return items
      if (!!object.key && !!object.val) {
        if (Array.isArray(object.val)) {
          return items.filter(function (o) {
            return object.val.includes(context.getObjectValue(o, object.key))
          })
        } else {
          return items.filter(function (o) {
            return context.getObjectValue(o, object.key) === object.val
          })
        }
      } else {
        return items.filter(function (o) {
          return Object.keys(o).some(function (k) {
            return o[k] && JSON.stringify(o[k]) === JSON.stringify(object)
          })
        })
      }
    },
    filterBySelectionAndText (items, filters, filter, headers) {
      const cf = new this.$MultiFilters(items, filters, filter, headers)
      const context = this
      cf.registerFilter('selectedObject', function (selectedObject, items) {
        return context.filterByObject(selectedObject, items)
      })
      cf.registerFilter('b', function (b, items) {
        return context.filterByObject(b, items)
      })
      cf.registerFilter('c', function (c, items) {
        return context.filterByObject(c, items)
      })
      cf.registerFilter('searchText', function (searchText, items) {
        return context.textSearchByVisibleValues(items, searchText)
      })

      return cf.runFilters()
    },    
    filterByName (items, search) {
      return items.filter(item => !search ? true :
        Array.isArray(item.name) &&
        this.localize({ array: item.name }) &&
        this.localize({ array: item.name }).toLowerCase().includes(search.toLowerCase()))
    },
    filterByLocalizedArrays (items, search) {
      return items.filter(item => Object.keys(item).some(k => Array.isArray(item[k]) &&
        k !== 'description' &&
        typeof this.localize({ array: item[k] }) === 'string' &&
        this.localize({ array: item[k] }).toLowerCase().includes(search.toLowerCase())))
    },
    filterByText (val) {
      this.filters = this.$MultiFilters.updateFilters(this.filters, { searchText: val })
    },
    filterBySelector (val) {
      this.filters = this.$MultiFilters.updateFilters(this.filters, { selectedObject: val })
    },
    filterByB (val) {
      this.filters = this.$MultiFilters.updateFilters(this.filters, { b: val })
    },
    filterByC (val) {
      this.filters = this.$MultiFilters.updateFilters(this.filters, { c: val })
    }
  },
  computed: {
    storedSearch () {
      return JSON.parse(sessionStorage.getItem(`filterConfig:${this.table}:Search`))
    }
  }
}

import { mapState, mapGetters } from 'vuex'
export default {
  methods: {
    changeLocalizedArray ({
      array,
      langCode,
      value,
      allowedLangCodes = this.facilityLanguages,
      allRequired
    }) {
      if (allowedLangCodes && langCode) {
        if (!array) {
          return [{ language_code: langCode, value }]
        }
        let a = [...array]
        if (allRequired) {
          allowedLangCodes.forEach(lC => {
            if (!a.find(o => o.language_code === lC)) {
              a.push({ language_code: lC, value: null })
            }
          })
          a.find(o => o.language_code === langCode).value = value
        }
        else {
          if (!a.find(o => o.language_code === langCode)) {
            a.push({ language_code: langCode, value })
          }
          a.find(o => o.language_code === langCode).value = value
          if (!value) {
            const i = a.indexOf(a.find(o => o.language_code === langCode))
            a.splice(i, 1)
          }
        }
        a = a.filter(o => allowedLangCodes.includes(o.language_code)) // Remove unallowed localized values
        return a.length ? a : null
      } 
    },
    sameLocalizedArrays ({ array1, array2 }) {
      if ((array1 && !array2) || (array2 && !array1)) {
        return false
      } else if (!array1 && !array2) {
        return true
      } else {
        let result = true
        array1.forEach(local1 => {
          const sameLang = array2.find(local2 => local2.language_code === local1.language_code)
          if (!sameLang || (sameLang.value !== local1.value)) {
            result = false
          }
        })
        array2.forEach(local2 => {
          const sameLang = array1.find(local1 => local1.language_code === local2.language_code)
          if (!sameLang || (sameLang.value !== local2.value)) {
            result = false
          }
        })
        return result
      }
    },
    removeUnavailableLanguages ({ array, allowedLanguages, allRequired }) {
      const result = []
      if (array) {
        allowedLanguages.forEach((langCode) => {
          const localized = array.find((localObj) => localObj.language_code === langCode)
          if (localized) {
            const { language_code, value } = localized
            result.push({ language_code, value })
          } else if (allRequired) {
            result.push({ language_code: langCode, value: null })
          }
        })
      }
      return result
    }
  },
  computed: {
    ...mapState({
      facilities: 'facilities',
    }),
    localize () {
      return ({
        array,
        langCode = this.$i18n.locale,
        noDefault = false,
        defaultLanguage = this.facilityDefaultLanguageCode,
        defaultValue
      }) => {
        if (Array.isArray(array)) {
          const localizedName = array.find(nO => nO.languageCode === langCode)
          const defaultName = !noDefault && defaultLanguage ? array.find(nO => nO.languageCode === defaultLanguage) : null
          return localizedName ? localizedName.value : defaultName ? defaultName.value : defaultValue
        }
      }
    },
    facilityLanguages () {
      return this.facilities.read ? this.facilities.read.allowedLanguageCodes : []
    },
    facilityDefaultLanguageCode () {
      return this.facilities.read
        ? this.facilities.read.defaultLanguageCode
        : null
    }   
  }
}
